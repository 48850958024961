import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
    margin-bottom: 8px;
    font-weight: 500;
    font-size: ${props => (props.sub ? '.9em' : '1.3em')};
  `

const Title = ({ className, sub = false, text }) => {
  return (
    <Wrapper className={className} sub={sub}>
      {text}
    </Wrapper>
  )
}

export default Title