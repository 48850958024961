import React from 'react'
import styled from '@emotion/styled'
// components
import Title from './Title'

const Wrapper = styled.footer`
  padding: 6rem 1.5rem;
  width: 100%;
  background: ${props => props.theme.colors.footer.background};
  color: ${props => props.theme.colors.footer.highlight};
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    padding: 4rem 1rem;
  }
`

const List = styled.ul`
  display: flex;
  flex-flow: ${props => props.columnFlow ? 'column' : 'row wrap'};
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
  padding: ${props => props.padding ? props.padding : '0'}
`

const Item = styled.li`
  display: block;
  padding: 8px 0;
  flex: ${props => props.flex ? props.flex : 'auto'};
  a {
    text-decoration: none;
    transition: .3s;
    color: ${props => props.theme.colors.footer.text};
    &:hover {
      color: ${props => props.theme.colors.footer.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.footer.text};
    }
  }
`

const Footer = ({ navigation }) => {
  const footerLinks = [
    {
      title: '網站地圖',
      links: navigation
    },
    {
      title: '聯繫我們',
      links: [{
        title: 'theras.tw@gmail.com',
        link: 'mailto:theras.tw@gmail.com'
      }]
    }
  ]

  const SignWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .sign {
      font-size: 1.15em;
    }
    .sign .studio:after {
      content: '.';
      display: inline-block;
      color: ${props => props.theme.colors.rasPrimary};
      font-size: 1.3em;
    }
  `

  return (
    <Wrapper>
      <List padding="0 2rem">
        <Item flex='1 0 60%'>
          <SignWrap>
            <div className="sign">
              <Title className="studio" text="RAS Studio" /> 
              <Title text="提供最可靠、可用與安全性的技術。" sub />
            </div>
            <div>© 2019 RAS Studio. All Right Reserved.</div>
          </SignWrap>
        </Item>
        <Item flex='1 0 40%'>
          <List>
            {footerLinks.map(({ title, links }) => (
              <Item key={title} flex='1 1 50%'>
                <Title text={title} />
                <List columnFlow>
                  {links.map(nav => (
                    <Item key={nav.link}>
                      <a
                        href={nav.link}
                        rel="noopener noreferrer"
                      >
                        {nav.title}
                      </a>
                    </Item>
                  ))}
                </List>
              </Item>
            ))}
          </List>
        </Item>
      </List>
    </Wrapper>
  )
}

export default Footer
