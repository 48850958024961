import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const StyledImg = styled(Img)`
  object-fit: contain;
  width: 30px;
  margin-right: 8px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  span {
    padding: 0 !important;
    margin: 0 !important;
  }
`

const AuthorInfo = ({ author }) => {
  const { avatar, slug } = author;
  return (
    <Wrapper>
      <StyledImg
        fluid={avatar.fluid}
        imgStyle={{
          borderRadius: '100%'
        }}
      />
      <span>{slug}</span>
    </Wrapper>
  );
};

export default AuthorInfo;