import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import classnames from 'classnames';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { useSiteMetadata } from '../hooks/use-site-metadata'
import _debounce from 'lodash/debounce';
// components
import Burger from '@animated-burgers/burger-squeeze' 
// css
import '@animated-burgers/burger-squeeze/dist/styles.css' 

const Header = styled.header`
  width: 100%;
  border-bottom: 1px solid #E0E3EB;
`
const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;

  .brand {
    flex: 0 0 30%;
    justify-content: space-between;
    z-index: 11;
  }

  .brand .logo {
    font-size: 1.3em;
  }

  .brand .nav-burger {
    visibility: hidden;
  }

  .menu {
    flex: auto;
    justify-content: flex-end;
    z-index: 9;
  }

  ul {
    display: flex;
    align-items: center;
    padding: 1.5em;
    background: #fff;
  }

  li {
    display: inline-block;
    box-sizing: border-box;
  }

  .menu li {
    margin-left: 1em;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    transition: all 0.2s;
    color: ${props => props.theme.colors.nav.text};
    &:hover {
      color: ${props => props.theme.colors.nav.highlight};
    }
  }

  .burger .burger-lines,
  .burger .burger-lines:after,
  .burger .burger-lines:before { background-color: ${props => props.theme.colors.nav.text}; }

  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    .brand {
      flex: 0 0 100%;
    }
    .brand .nav-burger {
      visibility: visible;
    }
    .menu {
      display: flex;
      flex-direction: column;
      position: fixed;
      height: 75px;
      top: 0;
      justify-content: center;
      width: 100%;
      opacity: 0;
      transition-duration: .5s;
    }
    .menu.open {
      height: 100%;
      opacity: 1;
    };
    .menu li {
      padding: 18px;
      margin: 0;
    };
  }
`
const RESIZE_DEBOUNCE_MS = 100

const Menu = () => {
  const [burgerToggle, setBurgerToggle] = useState(false)
  const toggle = () => setBurgerToggle(!burgerToggle)
  const { menuLinks, title } = useSiteMetadata()
  const activeLinkStyle = {}
  const headerRef = useRef()

  const onResize = useMemo(() => _debounce(() => {
    setBurgerToggle(false)
  }, RESIZE_DEBOUNCE_MS, { leading: true }))

  useEffect(() => {
    const { current } = headerRef;
    if (current && typeof window !== undefined) {
      burgerToggle ? disableBodyScroll(headerRef) : enableBodyScroll(headerRef)
    }
  }, [burgerToggle, headerRef]);

  useResizeObserver({ ref: headerRef, onResize });

  return (
    <Header ref={headerRef}>
      <Nav>
        <ul className="brand">
          <li className="logo">
            <Link to="" activeStyle={activeLinkStyle}>
              {title}
            </Link>
          </li>
          <li className="nav-burger">
            <Burger
              isOpen={burgerToggle}
              onClick={toggle}
              style={{ fontSize: '8px' }}
            />
          </li>
        </ul>
        <ul
          className={classnames("menu", {
              "open": burgerToggle
            }
          )}
        >
          {menuLinks.map(({ name, slug, href }) => (
            <li key={name}>
              {
                slug ? (
                  <Link to={slug} activeStyle={activeLinkStyle}>
                    {name}
                  </Link>
                ) : (
                  <a
                    href={href}
                    rel="noopener noreferrer"
                  >
                    {name}
                  </a>
                )
              }
            </li>
          ))}
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu
